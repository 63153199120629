const localeTranslations = {
  language: {
    en: "Language:",
    fr: "Langue:",
    de: "Sprache:",
    jp: "言語：",
    no: "Språk:",
    es: "Idioma:",
    zh: "语言：",
  },
  settingsBasedOnLocation: {
    en: "Based on your location we have changed your settings to:",
    fr: "D’après vos données de localisation, nous avons changé vos paramètres à :",
    de: "Auf Basis Ihres Standortes haben wir Ihre Einstellungen folgendermaßen geändert:",
    jp: "お住いのエリアに合わせて、以下の配送エリア、言語を設定：",
    no: "Basert på stedet der du befinner deg, har vi endret innstillingene dine til:",
    es: "En función de su ubicación, hemos cambiado su configuración a:",
    zh: "根据您的位置，我们已经更改了您的设置至：",
  },
  prefShippingRegion: {
    en: "Please select your preferred Shipping Region:",
    fr: "Veuillez sélectionner la région d’expédition souhaitée :",
    de: "Bitte wählen Sie Ihre bevorzugte Versandregion:",
    jp: "ご希望の配送エリアをお選びください：",
    no: "Velg ønsket fraktregion:",
    es: "Seleccione su región de envío de preferencia:",
    zh: "请选择您的首选配送区域：",
  },
  languageAsLocalizedString: {
    en: "English",
    fr: "Français",
    de: "Deutsch",
    jp: "日本語",
    no: "Norsk",
    es: "Español",
    zh: "简体中文",
  },
  yesIAgree: {
    en: "Yes, I agree",
    fr: "Oui, j’accepte",
    de: "Ja, ich bin einverstanden",
    jp: "設定する",
    no: "Ja, jeg godtar",
    es: "Sí, estoy de acuerdo",
    zh: "是的，我同意",
  },
  diffShippingRegion: {
    en: "Or select a different Shipping Region:",
    fr: "Ou sélectionnez une autre région d’expédition :",
    de: "Oder wählen Sie eine andere Versandregion:",
    jp: "または、別の配送エリアをお選びください：",
    no: "Eller velg en annen fraktregion:",
    es: "O bien seleccione una región de envío diferente:",
    zh: "或选择另一配送区域：",
  },
  prefferedLang: {
    en: "Please select your preferred Language:",
    fr: "Veuillez sélectionner votre langue de préférence :",
    de: "Bitte wählen Sie Ihre bevorzugte Sprache:",
    jp: "ご希望の言語をお選びください：",
    no: "Velg ønsket språk:",
    es: "Seleccione su región de envío de preferencia:",
    zh: "请选择您的首选语言：",
  },
  shippingRegion: {
    en: "Shipping Region:",
    fr: "Région d’expédition :",
    de: "Versandregion:",
    jp: "配送エリア：",
    no: "Fraktregion:",
    es: "Región de envío:",
    zh: "配送区域：",
  },
  welcome: {
    en: "Welcome to Arcteryx.com",
    fr: "Bienvenue sur Arcteryx.com",
    de: "Willkommen bei Arcteryx.com",
    jp: "Arcteryx.comへようこそ",
    no: "Velkommen til Arcteryx.com",
    es: "Bienvenido/a a Arcteryx.com",
    zh: "欢迎访问Arcteryx.com",
  },
};

export default localeTranslations;
