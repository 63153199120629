import lightboxStyles from "../scss/lightbox.scss";
import Lightbox from "./Lightbox";
import Cookies from "universal-cookie";
import { oneTrustElementId, signUpAndWinElementId, beforeYouGoElementId } from "../constants/elementIds";
import { getCmsMessageByFieldName } from "@arcteryx/js-cms";
import { isAuthenticated } from "@arcteryx/js-auth";

import { createClient } from "@sanity/client";
import {
  siteSettingsDocumentQuery,
  siteSettingsQueryParams,
  siteSettingsStaticSearchLinksDocumentQuery,
} from "@arcteryx/sanity-content";
import React from "react";
import ReactDOM from "react-dom";
import { PortableText } from "@portabletext/react";
import Config from "../../common/config";
import { EMAIL_SIGNUP } from "../constants/links.js";

export const langMap = {
  en: "english",
  fr: "french",
  es: "spanish",
  de: "german",
  no: "norwegian",
  zh: "chinese",
  ja: "japanese",
  jp: "japanese",
};

export const translations = {
  refineLabel: {
    en: "Refine",
    fr: "Affiner",
    de: "Filter",
    zh: "细化",
    jp: "絞り込む",
    es: "Restringir",
    no: "Begrens",
  },
  filtersLabel: {
    en: "Filters",
    fr: "Filtres",
    de: "optimieren",
    zh: "过滤器",
    jp: "フィルター",
    es: "Filtros",
    no: "Filtre",
  },
  startTypingToSearch: {
    en: "Start Typing to Search for Products",
    fr: "Commencez à saisir le nom du produit que vous cherchez",
    de: "Zwecks Produktsuche anfangen zu tippen",
    zh: "输入查找产品",
    jp: "入力して製品を検索する",
    es: "Empiece a escribir para buscar productos",
    no: "Begynn å skrive for å søke etter produkter",
  },
  wordNew: {
    en: "New",
    fr: "Nouveau",
    de: "Neu",
    zh: "新货",
    jp: "New",
    es: "Nuevo",
    no: "Ny",
  },
  revised: {
    en: "Revised",
    fr: "Révisé",
    de: "Überarbeitet",
    zh: "修改",
    jp: "Redesigned",
    es: "Revisado",
    no: "Revidert utgave",
  },
  viewProduct: {
    en: "Shop Now",
    fr: "Achetez maintenant",
    de: "Jetzt kaufen",
    zh: "立即选购",
    jp: "今すぐ購入",
    es: "Comprar ahora",
    no: "Shop nå",
  },
  xx: {
    en: "",
    fr: "",
    de: "",
    zh: "",
    jp: "",
    es: "",
    no: "",
  },
};

const documentBody = document.body;
const menuOpenClass = "menu--open";
const cookie = new Cookies(null, { path: "/" });
export const COOKIE_EXPIRATION_DURATION = 365;

/** Determine if the dropdown menu should be disabled
 * Applies to desktop and mobile views
 *
 * @param {string} country
 * @returns boolean
 */
export function isNavDropdownEnabled(country) {
  const navDropDownDisabledCountries = [];
  return !navDropDownDisabledCountries.includes(country?.trim().toLowerCase());
}

export function removeQueryStringParameter(url, key) {
  let newUrl = url;
  const urlparts = url.split("?");

  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(key)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // Reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      // Idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    newUrl = urlparts[0] + (pars.length > 0 ? `?${pars.join("&")}` : "");
    return newUrl;
  }

  return newUrl;
}

export function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  const separator = uri.indexOf("?") > -1 ? "&" : "?";

  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }

  // If adding country to url, add it before search
  if (uri.match("search=")) {
    const arr = uri.split("&");
    return `${arr[0] + separator + key}=${value}&${arr[1]}`;
  }

  return `${uri + separator + key}=${value}`;
}

export function querySt(ji, or) {
  let hu = or ? or : window.location.search.substring(1),
    gy = hu.split("&"),
    ft,
    i;

  for (i = 0; i < gy.length; i++) {
    ft = gy[i].split("=");

    if (ft[0].toLowerCase() === ji.toLowerCase()) {
      return ft[1];
    }
  }
  return "";
}

export function fadeIn(el) {
  if (el) {
    el.style.display = "block";
  }
}

export function fadeOut(el) {
  if (el) {
    el.style.display = "none";
  }
}

/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
export function htmlToElement(htmlString) {
  const template = document.createElement("template");
  template.innerHTML = htmlString;
  return template.content.firstChild;
}

export function htmlDecode(str) {
  const e = document.createElement("span");
  e.innerHTML = str;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function getContent(url, type, callback) {
  const xhr = new XMLHttpRequest();
  let responseType = "json";

  if (type !== null) {
    responseType = type;
  }

  xhr.open("GET", url, true);
  xhr.responseType = responseType;
  xhr.onload = function() {
    const { status } = xhr;
    if (status === 200) {
      callback(null, xhr.response);
    } else {
      callback(status, xhr.response);
    }
  };
  xhr.send();
}

/**
 * @brief Function to check if String ends with a given pattern. Workaround for IE not supporting endsWith
 * @param str
 * @param pattern
 * @returns {boolean}
 */
export function endsWith(str, pattern) {
  const d = str.length - pattern.length;
  return d >= 0 && str.lastIndexOf(pattern) === d;
}

/**
 * @brief Converts the product model to match the new PDP model URL.
 *        Specifically for womens products ending -w or products with periods in them (e.g. B.A.C hat)
 * @param model
 * @returns {String} convertedModel
 */
export function modelConversionForNewPDP(model) {
  let newModel = model;
  if (typeof model !== "undefined") {
    if (model.indexOf(".") > 0) {
      newModel = model.replace(/\./g, "-");
    }

    if (endsWith(newModel, "-W")) {
      newModel = newModel.substr(0, newModel.lastIndexOf("-W"));
    } else if (endsWith(newModel, "-w")) {
      newModel = newModel.substr(0, newModel.lastIndexOf("-w"));
    }
  }

  return newModel;
}

export function getLanguageFromLangCode(language) {
  switch (language) {
    case "en":
      return "ENGLISH";
    case "fr":
      return "FRENCH";
    case "de":
      return "GERMAN";
    case "zh":
      return "CHINESE";
    case "jp":
      return "JAPANESE";
    case "es":
      return "SPANISH";
    case "no":
      return "NORWEGIAN";
    default:
      return "ENGLISH";
  }
}

export function domReady(callback) {
  if (document.readyState === "loading") {
    window.addEventListener("DOMContentLoaded", () => {
      callback();
    });
  } else {
    // We've missed the DOMContentLoaded event, run callback now
    callback();
  }
}

export function setCookieExpiration(days) {
  const date = new Date();
  return date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
}

export function getCountry(ctx) {
  const urlParams = document.location.pathname;
  const urlSearchParams = new URLSearchParams(document.location.search);
  const [_, countryUrlParam] = urlParams.match("^/([a-zA-Z]{2})/([a-zA-Z]{2})(/.*)?") || [];
  const countryUrlSearchParam = urlSearchParams.get("country");
  const countryCookie = cookie.get("country");

  if (ctx.hasAttribute("country")) {
    return ctx.getAttribute("country");
  }

  if (countryUrlParam) {
    return countryUrlParam;
  }

  if (countryUrlSearchParam) {
    return countryUrlSearchParam;
  }

  if (countryCookie) {
    return countryCookie;
  }

  return "ca";
}

export function getLanguage(ctx) {
  if (ctx.hasAttribute("language")) {
    return ctx.getAttribute("language");
  }

  return LANGUAGE;
}

export function appendCountryLanguage(el, country, language) {
  if (country && language) {
    // Header links
    el.querySelectorAll(
      "nav .sub-menu .container ul li a, nav a#about, a#find-a-store, #cartInfo, .contact a.email__signup:not(.is--outlet)"
    ).forEach(navLink => {
      const href = navLink.getAttribute("href");
      if (href.indexOf("//") === -1) {
        const newHref = `/${country}/${language}${href}`;
        navLink.href = newHref;
      }
    });

    // Footer links
    el.querySelectorAll("#footer a").forEach(navLink => {
      const href = navLink.getAttribute("href");
      if (href && href.indexOf("//") === -1 && href.indexOf("tel:") === -1) {
        const newHref = `/${country}/${language}${href}`;
        navLink.href = newHref;
      }
    });
  }
}

export function csHelpPhoneNumber(country) {
  const phoneNumbers = {
    at: "0043720880435",
    be: "003228081137",
    ca: "1-866-458-2473",
    ch: "0041315281272",
    cz: "00420228880004",
    de: "+49 89 541 979 28",
    dk: "004589880637",
    es: "+34919495551",
    fi: "00358942450675",
    fr: "0033975186288",
    gb: "00442035100658",
    ie: "0035316917059",
    it: "00390694800672",
    jp: "03-6631-0833",
    nl: "0031858883774",
    no: "004781503100",
    se: "0046852503218",
    us: "1-866-458-2473",
    pl: "+48 22 307 88 88",
  };

  if (phoneNumbers[country]) {
    return phoneNumbers[country];
  }
  return phoneNumbers.ca; // Default
}

export function updateCartCount(el, market, country) {
  const cookieCount = parseInt(cookie.get(`CartCount-${market.toUpperCase()}`), 10);

  if (cookieCount > 0) {
    el.querySelector("#cartItems").innerHTML = cookieCount;
  } else {
    el.querySelector("#cartItems").innerHTML = "";
  }
}

export function isUserLoggedIn(el, selector) {
  if (isAuthenticated()) {
    el.querySelector(selector).classList.remove("NOTLOGGEDIN");
    el.querySelector(selector).classList.add("LOGGEDIN");
    return "LOGGEDIN";
  }
  el.querySelector(selector).classList.add("NOTLOGGEDIN");
  el.querySelector(selector).classList.remove("LOGGEDIN");
  return "NOTLOGGEDIN";
}

export function toggleForStaff(el, selector, show = true) {
  if (el.querySelectorAll(selector)) {
    el.querySelectorAll(selector).forEach(element => {
      if (show) {
        element.classList.remove("staff-block");
      } else {
        element.classList.add("staff-block");
      }
    });
  }
}

export function updateClassNames(el, selector, country, language) {
  el.querySelector(selector).classList.add(country.toUpperCase());
  el.querySelector(selector).classList.add(getLanguageFromLangCode(language));
  // ex JP or EN
  el.querySelector(selector).classList.add(language.toUpperCase());

  Array.from(document.body.classList).forEach(className => {
    el.querySelector(selector).classList.add(className);
  });

  if (querySt("simplify").toLowerCase() === "y") {
    el.querySelector(selector).style.display = "none";
  }

  if (document.querySelector("meta[id='PageName']")) {
    const names = document
      .querySelector("meta[id='PageName']")
      .content.replace(/\n/, "")
      .split(" ")
      .filter(x => x != "");
    names.forEach(name => {
      el.querySelector(selector).classList.add(name.toUpperCase());
    });
  }
}

export const buildHeadMetaItems = () => {
  const msApplicationTileColor = document.createElement("meta");
  msApplicationTileColor.name = "msapplication-TileColor";
  msApplicationTileColor.content = "#FFFFFF";

  const themeColor = document.createElement("meta");
  themeColor.name = "theme-color";
  themeColor.content = "#FFFFFF";

  const appleTouchIcon = document.createElement("link");
  appleTouchIcon.rel = "apple-touch-icon";
  appleTouchIcon.sizes = "180x180";
  appleTouchIcon.href = `https://images.arcteryx.com/apple-touch-icon.png`;

  const favicon144x144 = document.createElement("link");
  favicon144x144.rel = "icon";
  favicon144x144.type = "image/x-icon";
  favicon144x144.sizes = "144x144";
  favicon144x144.href = `https://images.arcteryx.com/favicon-144x144.ico`;

  const favicon96x96 = document.createElement("link");
  favicon96x96.rel = "icon";
  favicon96x96.type = "image/x-icon";
  favicon96x96.sizes = "96x96";
  favicon96x96.href = `https://images.arcteryx.com/favicon-96x96.ico`;

  const favicon64x64 = document.createElement("link");
  favicon64x64.rel = "icon";
  favicon64x64.type = "image/x-icon";
  favicon64x64.sizes = "64x64";
  favicon64x64.href = `https://images.arcteryx.com/favicon.ico`;

  const favicon48x48 = document.createElement("link");
  favicon48x48.rel = "icon";
  favicon48x48.type = "image/x-icon";
  favicon48x48.sizes = "48x48";
  favicon48x48.href = `https://images.arcteryx.com/favicon-48x48.ico`;

  const favicon32x32 = document.createElement("link");
  favicon32x32.rel = "icon";
  favicon32x32.type = "image/png";
  favicon32x32.sizes = "32x32";
  favicon32x32.href = `https://images.arcteryx.com/favicon-32x32.png`;

  const favicon16x16 = document.createElement("link");
  favicon16x16.rel = "icon";
  favicon16x16.type = "image/png";
  favicon16x16.sizes = "16x16";
  favicon16x16.href = `https://images.arcteryx.com/favicon-16x16.png`;

  const manifest = document.createElement("link");
  manifest.rel = "manifest";
  manifest.href = `${SCRIPTS_URL}/graphics/app-icons/site.webmanifest`;

  const safariPinnedTab = document.createElement("link");
  safariPinnedTab.rel = "mask-icon";
  safariPinnedTab.color = "var(--colour-black)";
  safariPinnedTab.href = `${SCRIPTS_URL}/graphics/app-icons/safari-pinned-tab.svg`;

  const preconnImgix = document.createElement("link");
  preconnImgix.rel = "preconnect";
  preconnImgix.href = "https://arc-cms-prod.imgix.net";
  preconnImgix.crossOrigin = "";

  const preconnDynImgix = document.createElement("link");
  preconnDynImgix.rel = "preconnect";
  preconnDynImgix.href = "https://images-dynamic-arcteryx.imgix.net";
  preconnDynImgix.crossOrigin = "";

  const preconnScripts = document.createElement("link");
  preconnScripts.rel = "preconnect";
  preconnScripts.href = `${SCRIPTS_URL}`;
  preconnScripts.crossOrigin = "";

  const preconnUIComponents = document.createElement("link");
  preconnUIComponents.rel = "preconnect";
  preconnUIComponents.href = PUBLICPATHURL;
  preconnUIComponents.crossOrigin = "";

  document.head.appendChild(preconnImgix);
  document.head.appendChild(preconnDynImgix);
  document.head.appendChild(preconnScripts);
  document.head.appendChild(preconnUIComponents);
  document.head.appendChild(msApplicationTileColor);
  document.head.appendChild(themeColor);
  document.head.appendChild(appleTouchIcon);
  document.head.appendChild(favicon144x144);
  document.head.appendChild(favicon96x96);
  document.head.appendChild(favicon64x64);
  document.head.appendChild(favicon48x48);
  document.head.appendChild(favicon32x32);
  document.head.appendChild(favicon16x16);
  document.head.appendChild(manifest);
  document.head.appendChild(safariPinnedTab);
};

export function initializeLightbox(el) {
  if (!document.getElementById("lightbox-styles")) {
    const lb = document.createElement("style");
    lb.type = "text/css";
    lb.id = "lightbox-styles";
    lb.appendChild(document.createTextNode(lightboxStyles));
    document.head.appendChild(lb);
  }

  el.querySelectorAll("[lightbox]").forEach(el => {
    el.lightbox = new Lightbox(el);
  });
}

export const hideInvalidElementsInWebView = () => {
  window.addEventListener("load", () => {
    const nodes = document.querySelectorAll(
      ".single-facility .breadcrumb__list-item:not(:last-child), #arcfavourites .banner, #guide-picks .shop__banner, .row.help__side-panel, .customer-centre__info-panel, #account-header, body:not(#Checkout) form h2, .CONFIRMATION #trustpilot-form, .CONFIRMATION #shop-now, #iPerceptionsFrame"
    );
    nodes.forEach(node => {
      node.remove();
    });
    const breadcrumbNode = document.querySelector("#breadcrumb");
    if (breadcrumbNode.closest(".single-facility") === null) {
      breadcrumbNode.remove();
    }
  });
};

export const hideInvalidElementsInIframe = () => {
  const styles = document.createElement("style");
  styles.innerHTML = `
  #${oneTrustElementId} {
    display:none;
  }

  #arcteryx-header {
    display: none;
  }

  .qa--footer {
    display: none;
  }

  .embeddedServiceHelpButton {
    display: none !important;
  }
  `;
  document.head.appendChild(styles);
};

export function hideElementInIframe(el, selector) {
  if (window.self !== window.top || querySt("rurl") !== "") {
    hideInvalidElementsInIframe();
    el.querySelector(selector).style.display = "none";
    document.body.classList.add("is-iframe");
    document.querySelector("html").style.scrollbarWidth = "none"; // Hide scrollbars for FF
    document.querySelectorAll("a").forEach(link => {
      link.target = "_blank";
    });

    if (querySt("rurl") !== "") {
      const span = document.createElement("span");
      span.classList.add("fa");
      span.classList.add("fa-times");
      span.style.color = "#fff";
      span.style.display = "inline-block";
      span.style.padding = "17px 0";
      span.style.fontSize = "40px";
      span.innerHTML = "×";
      const link = document.createElement("a");
      link.href = querySt("rurl");
      link.appendChild(span);
      const close = document.createElement("div");
      close.classList.add("close");
      close.style.color = "#FFF";
      close.style.width = "50px";
      close.style.height = "50px";
      close.style.textAlign = "center";
      close.style.position = "fixed";
      close.style.top = "0";
      close.style.right = "0";
      close.style.background = "var(--colour-black)";
      close.style.cursor = "pointer";
      close.style.fontStyle = "normal";
      close.style.fontFamily = "var(--font-helvetica)";
      close.appendChild(link);
      const lightbox = document.createElement("div");
      lightbox.classList.add("lightbox__mobile-close");
      lightbox.appendChild(close);
      document.body.appendChild(lightbox);
    }
  }
}

export function toggleHoverForNav(el, root, market) {
  if (el.classList) {
    el.classList.toggle("hover");

    if (el.nextElementSibling) {
      el.nextElementSibling.classList.toggle("hover-effect");
    }
  }

  if (el.classList.contains("hover")) {
    documentBody.classList.add(menuOpenClass);
    root.querySelector(`.${market.toUpperCase()}`).classList.add("is-hover");
    document.querySelector("#content--overlay").style.display = "block";
  } else {
    documentBody.classList.remove(menuOpenClass);
    root.querySelector(`.${market.toUpperCase()}`).classList.remove("is-hover");
    document.querySelector("#content--overlay").style.display = "none";
  }
}

export function removeHoverForNav(el) {
  if (el.classList) {
    el.classList.remove("hover");

    if (el.nextElementSibling) {
      el.nextElementSibling.classList.remove("hover-effect");
    }
  }
}

export function toggleOverflowForNav(el) {
  const headerSubMenu = el.querySelector("nav .sub-menu");

  if (document.querySelector("html.hide-overflow")) {
    headerSubMenu.classList.add("hide-overflow");

    ["html", "body"].forEach(selector => {
      document.querySelector(selector).style.overflowX = "";
    });
  } else {
    headerSubMenu.classList.remove("hide-overflow");

    ["html", "body"].forEach(selector => {
      document.querySelector(selector).style.overflowX = "hidden";
    });
  }
}

export function addHoverForNav(el, root, market) {
  if (el.classList) {
    el.classList.add("hover");

    if (el.nextElementSibling) {
      el.nextElementSibling.classList.add("hover-effect");
    }
  }
  root.querySelector(`.${market.toUpperCase()}`).classList.add("is-hover");
  document.querySelector("#content--overlay").style.display = "block";
  documentBody.classList.add(menuOpenClass);
}

export function removeAllHoverForNav(root, market) {
  root.querySelectorAll("nav .sub-menu").forEach(element => {
    element.classList.remove("hover-effect");
  });
  root.querySelectorAll("nav .head").forEach(element => {
    if (element.nextElementSibling && !element.nextElementSibling.matches(":hover")) {
      element.classList.remove("hover");
    }
  });
  root.querySelector(`.${market.toUpperCase()}`).classList.remove("is-hover");
  document.querySelector("#content--overlay").style.display = "none";
  documentBody.classList.remove(menuOpenClass);
}

export function injectWCAGStatement(content) {
  domReady(() => {
    const htmlToInsert = `<div id="wcag__statement"></div>`;
    document.querySelector("body").insertAdjacentHTML("afterbegin", htmlToInsert);
    import("./WCAGStatement.js").then(obj => obj.default(content));
  });
}

// Changes header based on a custom event
export function switchToCheckoutHeader(root) {
  window.addEventListener("change-header", e => {
    if (e.detail.isCheckout && !root.firstChild.classList.contains("CHECKOUT")) {
      root.firstChild.classList.add("CHECKOUT");
    }
    if (!e.detail.isCheckout && root.firstChild.classList.contains("CHECKOUT")) {
      root.firstChild.classList.remove("CHECKOUT");
    }
  });
}

export function listenForImportantMessage() {
  window.addEventListener(
    "important-message",
    event =>
      new Lightbox(null, {
        type: "html",
        openOnCreate: true,
        html: event.detail.html,
      })
  );
}

export function trackEvent(obj) {
  if (typeof ARCTERYX !== "undefined" && typeof ARCTERYX.analytics !== "undefined" && ARCTERYX.analytics.trackEvent) {
    ARCTERYX.analytics.trackEvent(obj);
  }
}

export function trackClick(navLink) {
  if (typeof ARCTERYX !== "undefined" && typeof ARCTERYX.analytics !== "undefined") {
    let gender = navLink.getAttribute("data-prefix") ? navLink.getAttribute("data-prefix") : "neutral";

    const subcat = navLink.parentNode.classList.contains("sectionhead")
      ? ""
      : navLink.parentNode.className.replace("sectionhead", "").trim();
    const parentcat = navLink.parentNode.classList.contains("sectionhead")
      ? navLink.parentNode
      : navLink.parentNode.parentNode.previousElementSibling;
    let category = parentcat.classList
      .toString()
      .replace("sectionhead", "")
      .trim()
      .replace(" ", "-");

    if (navLink.classList.contains("social-na")) {
      gender = "social";
      category = navLink.classList
        .toString()
        .replace("social-na", "")
        .trim();
    } else if (navLink.parentNode.parentNode.parentNode.classList.contains("explore")) {
      gender = "explore";
    }

    trackEvent({
      category: "TopNavMenu",
      action: "MenuSelected",
      label: `${gender} : ${category}${subcat ? " : " : ""}${subcat}`,
      eVars: {
        eVar59: `${gender} : ${category}${subcat ? " : " : ""}${subcat}`,
      },
      events: "event19",
    });
  }
}

export function removeCountryFromProductName(originalProductName) {
  return originalProductName ? originalProductName.replace(/ \(us\)| \(china\)/gi, "") : originalProductName;
}

export const ACTION_KEY_CODES = [
  13, // Enter
  32, // Space
];

export const isVirtualAdvisorEnabled = (country, language) => {
  const invalidCountries = ["au", "jp", "cn", "nz"];
  const validLanguages = ["en", "fr"];
  const countryLowerCase = country.toLowerCase();
  const languageLowerCase = language.toLowerCase();

  return !invalidCountries.includes(countryLowerCase) && validLanguages.includes(languageLowerCase);
};

export const loadScript = url => {
  const scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  scriptElement.async = true;
  scriptElement.src = url;
  document.getElementsByTagName("head")[0].appendChild(scriptElement);
};

export const appendScriptToBody = (url, scriptId) => {
  const scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  scriptElement.async = true;
  scriptElement.src = url;
  if (scriptId) {
    scriptElement.id = scriptId;
  }
  document.getElementsByTagName("body")[0].appendChild(scriptElement);
};

export const showAppStore = (el, lang) => {
  // IF IPHONE, THEN SHOW LINK TO APPSTORE
  if (/iPhone/.test(navigator.userAgent) && !window.MSStream) {
    const iosonlytext = {
      EN:
        "Shop, search and explore from anywhere. Streamlined and easy to use, the Arc'teryx app is the quickest way to connect with Arc’teryx on your phone.",
      ZH: "随时随地选购、搜索和了解商品。Arc’teryx app使用流畅简单，是您通过手机以连接 Arc’teryx的最快方式。",
      FR:
        "Découvrir, rechercher et acheter où que vous soyez. Simple et facile d’utilisation, l’application Arc’teryx est le moyen le plus rapide de vous connecter à Arc’teryx depuis votre téléphone.",
      DE:
        "Einkaufen, suchen und entdecken – egal wo du bist. Die einfache und unkomplizierte Arc’teryx App bietet dir die schnellste Möglichkeit, Arc’teryx via Smartphone zu erreichen.",
      NO:
        "Shop, søk og finn informasjon samme hvor du er. Den strømlinjede, brukervennlige Arc’teryx-appen er den raskeste måten å koble seg til Arc’teryx på med telefonen.",
      JP:
        "ショッピングや検索、探求をどこからでも。最新の簡単に使えるアークテリクス アプリは、お使いのスマートフォンとアークテリクスを最速でつなぐツールです。",
      ES:
        "Compra, busca y explora desde cualquier lugar. Optimizada y fácil de usar, la app de Arc’teryx es la forma más rápida de conectar con Arc’teryx desde tu teléfono.",
    };

    el.querySelector(".bottom-bar").insertAdjacentHTML(
      "afterend",
      `<div class='iphone-callout'><p>${iosonlytext[lang]}</p><a href='https://itunes.apple.com/ca/app/arcteryx/id1183113553?mt=8' target='_blank'><img src='https://images-dynamic-arcteryx.imgix.net/campaign/third-party/appstore-${lang}.svg' class='appstore'></a></div>`
    );
  }
};

export const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") !== -1;

export const addEmailSignupLinkClickListeners = (root, country) => {
  const invalidCountries = ["au", "jp", "cn", "nz"];

  // Use legacy email sign up for Firefox and invalid country users
  if (isFirefox || invalidCountries.includes(country)) {
    return;
  }

  const links = root.querySelectorAll(".email__signup");

  const updateEmailSignupWindowCountValue = event => {
    // Wunderkind listens to this global variable to display their email sign up modal
    window.emailSignupToggleCount = window.emailSignupToggleCount ? window.emailSignupToggleCount + 1 : 1;
  };

  links.forEach(link => {
    link.href = EMAIL_SIGNUP;
    link.addEventListener("click", updateEmailSignupWindowCountValue);
  });
};

export const showVirtualAdvisorFooterLinks = (country, language, documentRoot) => {
  const setVirtualAdvisorEnabledLinks = (show = false) => {
    documentRoot.querySelectorAll(".virtual-advisor-enabled").forEach(link => {
      link.style.display = show ? "inline-block" : "none";
    });
    documentRoot.querySelectorAll(".virtual-advisor-disabled").forEach(link => {
      link.style.display = show ? "none" : "inline-block";
    });
  };

  setVirtualAdvisorEnabledLinks(isVirtualAdvisorEnabled(country, language));
};

// TODO: remove this function after the new fonts (Helvetica and ITC-Pro) have been deployed
export const injectURWFont = () => {
  const fontStyles = document.createElement("style");
  fontStyles.innerHTML = `
  <style>
  @import url("https://p.typekit.net/p.css?s=1&k=amb0fbb&ht=tk&f=36601.36602.36603.36604.36605.36606.36607.36608.36609.36610.36617.36618.36619.36620.36621.36622.36623.36624.36625.36626.36633.36634.36635.36636.36637.36638.36639.36640.36641.36642&a=131936418&app=typekit&e=css");

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/1f0265/00000000000000007735a839/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/1f0265/00000000000000007735a839/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/1f0265/00000000000000007735a839/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/f1d51b/00000000000000007735a83d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f1d51b/00000000000000007735a83d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f1d51b/00000000000000007735a83d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/5f44f1/00000000000000007735a840/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/5f44f1/00000000000000007735a840/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/5f44f1/00000000000000007735a840/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/d96df7/00000000000000007735a843/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/d96df7/00000000000000007735a843/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/d96df7/00000000000000007735a843/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/caf48f/00000000000000007735a846/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/caf48f/00000000000000007735a846/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/caf48f/00000000000000007735a846/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/b8eeae/00000000000000007735a849/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/b8eeae/00000000000000007735a849/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/b8eeae/00000000000000007735a849/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/1c5092/00000000000000007735a84b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/1c5092/00000000000000007735a84b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/1c5092/00000000000000007735a84b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/cd97e9/00000000000000007735a84f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/cd97e9/00000000000000007735a84f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/cd97e9/00000000000000007735a84f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/fe2f02/00000000000000007735a851/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/fe2f02/00000000000000007735a851/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/fe2f02/00000000000000007735a851/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din";
  src:url("https://use.typekit.net/af/0fba77/00000000000000007735a855/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/0fba77/00000000000000007735a855/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/0fba77/00000000000000007735a855/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/b27e1e/00000000000000007735a83b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/b27e1e/00000000000000007735a83b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/b27e1e/00000000000000007735a83b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/ba5c17/00000000000000007735a83e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/ba5c17/00000000000000007735a83e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/ba5c17/00000000000000007735a83e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/06eb22/00000000000000007735a841/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/06eb22/00000000000000007735a841/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/06eb22/00000000000000007735a841/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/413a03/00000000000000007735a844/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/413a03/00000000000000007735a844/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/413a03/00000000000000007735a844/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/c2cf88/00000000000000007735a847/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/c2cf88/00000000000000007735a847/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/c2cf88/00000000000000007735a847/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/b1d7bd/00000000000000007735a84a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/b1d7bd/00000000000000007735a84a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/b1d7bd/00000000000000007735a84a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/be96d8/00000000000000007735a84d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/be96d8/00000000000000007735a84d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/be96d8/00000000000000007735a84d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/3f20e7/00000000000000007735a850/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/3f20e7/00000000000000007735a850/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/3f20e7/00000000000000007735a850/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/5002a8/00000000000000007735a853/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/5002a8/00000000000000007735a853/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/5002a8/00000000000000007735a853/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-condensed";
  src:url("https://use.typekit.net/af/c85988/00000000000000007735a856/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/c85988/00000000000000007735a856/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/c85988/00000000000000007735a856/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/68a396/00000000000000007735a83c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/68a396/00000000000000007735a83c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/68a396/00000000000000007735a83c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/960873/00000000000000007735a83f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/960873/00000000000000007735a83f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/960873/00000000000000007735a83f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/40dae5/00000000000000007735a842/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/40dae5/00000000000000007735a842/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/40dae5/00000000000000007735a842/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/5788bc/00000000000000007735a845/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/5788bc/00000000000000007735a845/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/5788bc/00000000000000007735a845/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/96cf20/00000000000000007735a848/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/96cf20/00000000000000007735a848/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/96cf20/00000000000000007735a848/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/bd0e8a/00000000000000007735a84c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/bd0e8a/00000000000000007735a84c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/bd0e8a/00000000000000007735a84c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/f61b58/00000000000000007735a84e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/f61b58/00000000000000007735a84e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/f61b58/00000000000000007735a84e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/349829/00000000000000007735a852/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/349829/00000000000000007735a852/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/349829/00000000000000007735a852/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/8def5a/00000000000000007735a854/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/8def5a/00000000000000007735a854/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/8def5a/00000000000000007735a854/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
  }

  @font-face {
  font-family:"urw-din-semi-condensed";
  src:url("https://use.typekit.net/af/d4ff8c/00000000000000007735a857/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/d4ff8c/00000000000000007735a857/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/d4ff8c/00000000000000007735a857/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
  }

  .tk-urw-din { font-family: "urw-din",sans-serif; }
  .tk-urw-din-condensed { font-family: "urw-din-condensed",sans-serif; }
  .tk-urw-din-semi-condensed { font-family: "urw-din-semi-condensed",sans-serif; }
  </style>`;
  document.head.appendChild(fontStyles);
};

export const isJacketFinderPage = () => window.location.href.includes("jacket-finder");

export const isElementValid = (parent, elementIdClass) =>
  typeof parent.querySelector(elementIdClass) !== "undefined" && parent.querySelector(elementIdClass) !== null;

/* Remove any words wrapped in brackets e.g: Beta AR Men's (NAM) Jacket => Beta AR Men's Jacket */
export const formatProductName = productName => productName.replace(/ *\([^)]*\) */g, " ");

export const genderMapping = gender => {
  const formatGender = gender?.toLowerCase() || gender || "";
  const genderMap = {
    male: "mens",
    female: "womens",
    neutral: "unisex",
  };
  return genderMap[formatGender] || formatGender;
};

/**
 * Get query parameter value from URL, returns null if does not exist.
 *
 * @param {string} queryParam
 * @returns {null|string}
 */
export const getQueryParam = queryParam => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(queryParam);
};

export const disableAdobeLaunch = () => getQueryParam("disableAdobeLaunch") === "1";

export const fetchSanityContent = (query, params) => {
  const client = createClient({
    projectId: Config.getSanityProjectId(),
    dataset: Config.getSanityDataset(),
    useCdn: true, // prod needs to be true
    apiVersion: "2021-10-21", // use current date (YYYY-MM-DD) to target the latest API version
  });

  return client.fetch(query, params);
};

export const fetchSanityPromobarMessage = async (market, country, language) => {
  const settings = await fetchSanityContent(
    siteSettingsDocumentQuery,
    siteSettingsQueryParams({
      market,
      country,
      language: language === "jp" ? "ja" : language,
    })
  );
  return settings?.promoMessage?.portableText;
};

export const fetchSanityStaticSearchLinksSettings = async (market, country, language) => {
  try {
    const staticSearchLinks = await fetchSanityContent(
      siteSettingsStaticSearchLinksDocumentQuery,
      siteSettingsQueryParams({
        market: market === "outlet" ? "sale" : market,
        country,
        language: language === "jp" ? "ja" : language,
      })
    );
    if (staticSearchLinks) {
      return {
        staticSearchLinks,
      };
    }
  } catch (error) {
    console.error("Error fetching Sanity content:", error);
    throw error;
  }
  return null;
};

export const fetchDrupalPromobarMessage = async (market, country, language) => {
  try {
    let messageFieldName = null;

    // Only outdoor and sale can have promobar message
    if (market === "outdoor" || market === "sale") {
      messageFieldName = `promobar_${market}`;
    } else {
      throw new Error("Invalid Market.");
    }

    return await getCmsMessageByFieldName(CMS_URL, market, country, language, messageFieldName);
  } catch (e) {
    console.error(e);
    return null;
  }
};

const AddInternalLinkTracking = el => {
  //?intlnk=<Page Name>_<Campaign Type>_<Location>_<Link Text>
  //?intlnk=?intlnk=home_promo%20bar_header_arcteryx%20limited%20warranty
  //https://arcteryx.com/help/limited-warranty?intlnk=home_promo%20bar_header_Arc%27teryx%20Limited%20Warranty
  //pagename examples home, help/limited-warranty, catalog|mens|shell-jackets, product|mens|Alpha-SV-Jacket
  if (el) {
    const englishLinkText = el.hasAttribute("data-english-link-text") ? el.getAttribute("data-english-link-text") : "";
    let pageNameAnalytics = window.location.pathname;

    if (document.head.querySelector("meta[name='PageName']")) {
      pageNameAnalytics = document.head.querySelector("meta[name='PageName']").getAttribute("content");
    } else if (window.appEventDataProcess?.computedState?.page?.pageName) {
      pageNameAnalytics = window.appEventDataProcess?.computedState.page.pageName;
    } else if (window.digitalData?.page?.pageInfo?.pageName) {
      pageNameAnalytics = window.digitalData.page.pageInfo.pageName;
    }

    if (englishLinkText && pageNameAnalytics) {
      const campaignType = "promo bar";
      const location = "header";
      const searchParams = new URLSearchParams({
        intlnk: `${pageNameAnalytics.replace("&", "and")}_${campaignType}_${location}_${englishLinkText}`,
      });
      el.href = `${el.href}?${searchParams.toString()}`;
    }
  }
};

export const injectPromobarMessage = (el, market, country, language) => {
  domReady(async () => {
    let promoShown = false;

    if (Config.isSanityPromoBarEnabled()) {
      const portableText = await fetchSanityPromobarMessage(market, country, language);
      if (portableText) {
        const TextColorSpan = ({ children, value }) => <span style={{ color: value.value }}>{children}</span>;
        const PromoBarLink = ({ children, value }) => (
          <a href={value.href} data-english-link-text={value.analyticsLabel}>
            {children}
          </a>
        );

        ReactDOM.render(
          <PortableText
            value={portableText}
            components={{
              marks: {
                textColor: TextColorSpan,
                link: PromoBarLink,
              },
            }}
          />,
          el.querySelector(".header-info__message")
        );
        promoShown = true;
      }
    } else {
      const message = await fetchDrupalPromobarMessage(market, country, language);
      if (message) {
        el.querySelector(".header-info__message").innerHTML = message;
        promoShown = true;
      }
    }

    if (promoShown) {
      const links = el.querySelectorAll(".header-info__message a");
      if (links) {
        [...links].forEach(link => {
          AddInternalLinkTracking(link);
        });
      }
    } else {
      el.querySelector(".header-info__message").remove();
    }
  });
};

const toggleProductPage = condition => {
  if (document.querySelector("body").classList.contains("PRODUCT")) {
    document
      .querySelectorAll(
        "#content, .product__below-the-fold, .product__below-the-fold--wide, .product__alternate-images, .product__olapic-widget, .product__cross-sell"
      )
      .forEach(condition ? fadeIn : fadeOut);
  }
};

export const toggleRegularPage = condition => {
  if (condition) {
    fadeIn(document.querySelector("#content"));
  } else {
    fadeOut(document.querySelector("#content"));
  }
  toggleProductPage(condition);
};

export function isWebview() {
  return navigator.userAgent.match(/MobileAppV2/) !== null;
}

export const hideCookieConsent = () => {
  const styles = document.createElement("style");
  styles.innerHTML = `
  #${oneTrustElementId} {
    display:none
  }
  `;
  document.head.appendChild(styles);
};

export const isPDPPage = () => window.location.href.includes("/c/");

export const hideEmailSignupPopup = () => {
  domReady(() => {
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList" && document.querySelector(".bx-client-overlay")) {
          const styles = document.createElement("style");
          styles.innerHTML = `
            .bx-client-overlay {
              overflow: visible!important;
            }
          `;
          document.body.appendChild(styles);
          observer.disconnect();
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(document.querySelector("body"), { childList: true });
  });

  const styles = document.createElement("style");
  styles.innerHTML = `
  #${signUpAndWinElementId} {
    display:none!important
  }

  #${beforeYouGoElementId} {
    display:none!important
  }
  `;
  document.head.appendChild(styles);
};

export const setCountryAndLanguageCookies = (country, language) => {
  if (country && !document.cookie.includes(`country=${country}`)) {
    cookie.set("country", country.toLowerCase(), [{ expires: setCookieExpiration(COOKIE_EXPIRATION_DURATION) }]);
  }
  if (language && !document.cookie.includes(`language=${language}`)) {
    cookie.set("language", language.toLowerCase(), [{ expires: setCookieExpiration(COOKIE_EXPIRATION_DURATION) }]);
  }
};

export const getOneTrustLang = language => (language === "jp" ? "ja" : language);
