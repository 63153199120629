import Cookies from "universal-cookie";
import localeTranslations from "../../utils/locale-translations";
import { updateUrlCountryLanguage } from "../helpers/localeSwitcher";
import localeSwitcherHtml from "../templates/locale-switcher-sale";
import {
  updateQueryStringParameter,
  trackEvent,
  getCountry,
  getLanguage,
  setCookieExpiration,
  COOKIE_EXPIRATION_DURATION,
} from "./GeneralHelpers";

let SELECTEDCOUNTRY,
  SELECTEDLANGUAGE,
  MODALISOPEN,
  MODALOPTIONS = {
    nonEcomFirstTimeUser: false,
    firstTimeUser: false,
    returnUser: false,
  };
const cookie = new Cookies(null, { path: "/" });
class LocaleSwitcherSale extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.country = getCountry(this);
    this.language = getLanguage(this);

    // If FTUE only fire after complete load
    window.addEventListener("load", () => {
      if (
        (this.country && !document.cookie.includes(`country=${this.country}`)) ||
        (this.language && !document.cookie.includes(`language=${this.language}`))
      ) {
        this.setCookies(this.country, this.language);
      }
    });

    // Bind listeners to other components to trigger modal
    if (document.querySelector("body").classList.contains("CHECKOUT")) {
      document.addEventListener("ARCTERYXUICOMPONENTS.localeClick", e => {
        e.preventDefault();
        window.location.href = `${
          window.location.origin
        }/${this.country.toUpperCase()}/${this.language.toUpperCase()}/cart`;
      });
    } else {
      document.addEventListener("ARCTERYXUICOMPONENTS.localeClick", e => {
        this.launchModal(false);
        trackEvent({
          category: `${
            document.head.querySelector("meta[name='PageName']")
              ? document.head.querySelector("meta[name='PageName']").getAttribute("content")
              : document.title
          }`,
          action: `${
            document.head.querySelector("meta[name='PageName']")
              ? document.head.querySelector("meta[name='PageName']").getAttribute("content")
              : document.title
          } Interaction`,
          label: e.target === "arcteryx-sale-footer" ? "footer" : "header",
          eVars: {
            eVar88: e.target === "arcteryx-sale-footer" ? "footer: country_select" : "header: country_select",
          },
        });
      });
    }
  }

  launchModal(ftueNotClick) {
    MODALOPTIONS.firstTimeUser = Boolean(ftueNotClick);
    MODALISOPEN = true;
    this.redirectedFromNonEcom(ftueNotClick);
    // Prep component for mount && populate markup/logic
    const localeSwitcherSaleTemplate = document.createElement("template");
    const lang = this.language ? this.language : "EN";
    localeTranslations.defaultCountry = this.country.toUpperCase();
    localeSwitcherSaleTemplate.innerHTML = localeSwitcherHtml(localeTranslations, lang, MODALOPTIONS);
    this.root.appendChild(localeSwitcherSaleTemplate.content.cloneNode(true));
    // Transitions in
    setTimeout(() => {
      this.root.querySelector(".locale-modal").style.opacity = "1";
      this.root.querySelector(".locale-modal--close").style.opacity = "1";
      this.root.querySelector("#firstTimeUserExperience").style.opacity = "1";
    }, 400);
    this.bindEventListenersForModal(this.root);
    this.setCookies(this.country, this.language);
  }

  // Refresh the page with new country language selections
  refreshPage() {
    if (!SELECTEDLANGUAGE) {
      SELECTEDLANGUAGE = LANGUAGE;
    }
    trackEvent({
      category: `${
        document.head.querySelector("meta[name='PageName']")
          ? document.head.querySelector("meta[name='PageName']").getAttribute("content")
          : document.title
      }`,
      action: `${
        document.head.querySelector("meta[name='PageName']")
          ? document.head.querySelector("meta[name='PageName']").getAttribute("content")
          : document.title
      } Interaction`,
      label: `locale-select_${SELECTEDCOUNTRY}-${SELECTEDLANGUAGE}`,
      eVars: { eVar88: `locale-select_${SELECTEDCOUNTRY}-${SELECTEDLANGUAGE}` },
      events: "event88",
    });
    const redirectUrl = updateUrlCountryLanguage({
      country: SELECTEDCOUNTRY,
      language: SELECTEDLANGUAGE,
      isOldPageFormat: this.isOldPageFormat(),
    });
    window.location.href = redirectUrl;
  }

  // Bind click listenres specific to modal
  bindEventListenersForModal(el) {
    // Country selection
    el.querySelectorAll("#firstTimeUserExperience .ftue--selector__flag li").forEach(flag => {
      flag.addEventListener("click", () => {
        SELECTEDCOUNTRY = flag.dataset.country;
        this.setCookies(flag.dataset.country, null);
        el.getElementById("firstTimeUserExperience").classList.add("countrySelected");

        setTimeout(() => {
          el.getElementById("firstTimeUserExperience").classList.add("showLanguage");
          this.updateCountry(SELECTEDCOUNTRY, flag.textContent);
        }, 300);
      });
    });
    // Language selection
    el.querySelectorAll("#firstTimeUserExperience .ftue--selector__lang li").forEach(flag => {
      flag.addEventListener("click", () => {
        SELECTEDLANGUAGE = flag.dataset.lang;
        this.setCookies(null, flag.dataset.lang);
        el.getElementById("firstTimeUserExperience").classList.add("languageSelected");
        this.updateLanguage(flag.textContent);
        setTimeout(() => {
          el.querySelector(".ftue--content__footer").classList.add("deadBirdLoadAnim");
        }, 400);
        setTimeout(() => {
          this.refreshPage();
        }, 800);
      });
    });
    // Close the modal on agree
    if (el.querySelector("#firstTimeUserExperience a.button")) {
      el.querySelector("#firstTimeUserExperience a.button").addEventListener("click", () => {
        this.closeModal(el);
      });
    }
    el.querySelector(".locale-modal--close").addEventListener("click", () => {
      if (SELECTEDCOUNTRY && !SELECTEDLANGUAGE) {
        this.refreshPage();
      } else {
        this.closeModal(el);
      }
    });
    // Close if clicking outside
    el.querySelector(".locale-modal").addEventListener("click", e => {
      if (!el.querySelector(".locale-modal--wrap").contains(e.target)) {
        if (SELECTEDCOUNTRY && !SELECTEDLANGUAGE) {
          this.refreshPage();
        } else {
          this.closeModal(el);
        }
      }
    });
    // Close if pressing escape
    document.onkeydown = e => {
      const isEscape = "key" in e ? e.key == "Escape" || e.key == "Esc" : e.keyCode == 27;
      if (isEscape && MODALISOPEN) {
        if (SELECTEDCOUNTRY && !SELECTEDLANGUAGE) {
          this.refreshPage();
        } else {
          this.closeModal(el);
        }
      }
    };
  }

  redirectedFromNonEcom(ftueNotClick) {
    // If ftue and not a click
    if (ftueNotClick) {
      if (cookie.get("redirect_non_ecomm")) {
        MODALOPTIONS.nonEcomFirstTimeUser = true;
        MODALOPTIONS.firstTimeUser = false;
        cookie.remove("redirect_non_ecomm", [{ domain: "arcteryx.com" }]);
        return true;
      }
      MODALOPTIONS.nonEcomFirstTimeUser = false;
      return false;
    }
    MODALOPTIONS.nonEcomFirstTimeUser = false;
    MODALOPTIONS.firstTimeUser = false;
  }

  // Update the flag in the header/footer and FTUE modal
  updateCountry(countryCode, countryName) {
    this.root.querySelectorAll(".ftue--country .flag, .header .flag").forEach(flag => {
      flag.className = flag.className.replace(/flag-\w*[A-Z]\w*[A-Z]\w*/g, `flag-${countryCode.toUpperCase()}`);
    });
    this.root.querySelectorAll(".ftue--country .flag + .text").forEach(flag => {
      flag.textContent = countryName;
    });
  }

  // Update language in FTUE modal
  updateLanguage(language) {
    this.root.querySelectorAll(".ftue--language .text").forEach(text => {
      text.textContent = language;
    });
  }

  // Set country and language cookies
  setCookies(country, language) {
    if (country) {
      cookie.set("country", country.toLowerCase(), [{ expires: setCookieExpiration(COOKIE_EXPIRATION_DURATION) }]);
    }
    if (language) {
      cookie.set("language", language.toLowerCase(), [{ expires: setCookieExpiration(COOKIE_EXPIRATION_DURATION) }]);
    }
  }

  // Legacy check for old aspx layout
  isOldPageFormat() {
    return window.location.pathname.indexOf(".aspx") > -1;
  }

  closeModal(el) {
    MODALISOPEN = false;
    el.querySelector(".locale-modal").style.opacity = "0";
    window.setTimeout(() => {
      el.querySelector(".locale-modal").remove();
    }, 400);
  }
}

export default LocaleSwitcherSale;
