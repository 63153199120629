import OUTLETLogo from "../images/OUTLET-logo.svg";
import indexStyles from "../scss/locale-switcher-sale.scss";
const logo = OUTLETLogo.replace("<svg ", '<svg fill="black" ');

const localeSwitcherHtml = (translations, language, options) => `
  <div class="locale-modal">
  <div class="locale-modal--wrap">
    <style type="text/css">
      ${indexStyles}
    </style>
    <div id="firstTimeUserExperience">
      ${options.firstTimeUser || options.nonEcomFirstTimeUser ? '<div class="ftue--background"></div>' : ""}
      <div class="ftue--content ${options.firstTimeUser ? "" : "nonftue"} ${
  options.nonEcomFirstTimeUser ? "nonecom" : ""
}">
        <div class="ftue--content__header">
          <div class="ftue--content__logo">${logo}</div>
          <div class="ftue--content__settings">
            ${
              options.firstTimeUser
                ? `
                <h2>${translations.welcome[language]}</h2>
                <p>${translations.settingsBasedOnLocation[language]}</p>`
                : ""
            }
            ${
              options.nonEcomFirstTimeUser
                ? `
                <h2>${translations.welcome[language]}</h2>`
                : ""
            }
            <div class="ftue--country">
              <span>${translations.shippingRegion[language]} </span>
              <span class="flag flag-${translations.defaultCountry}"></span>
              <span class="text">${translations.defaultCountry}</span>
            </div>
            <div class="ftue--language">
              <span class="ftue--spacer">/</span>
              <span>${translations.language[language]}</span>
              <span class="text">${translations.languageAsLocalizedString[language]}</span>
            </div>
          </div>
          ${options.firstTimeUser ? `<a class="button">${translations.yesIAgree[language]}</a>` : ""}
        </div>
        <div class="ftue--content__footer">
          <div class="ftue--country-selector">
            ${
              options.firstTimeUser
                ? `<p class="ftue--content__info-head">${translations.diffShippingRegion[language]}</p>`
                : `<p class="ftue--content__info-head returnUser">${translations.prefShippingRegion[language]}</p>`
            }
            <ul class="ftue--selector__flag">
              <li data-country="AT">
                <span class="ftue--country flag flag-AT"></span
                ><span class="ftue--country text">Austria</span>
              </li>
              <li data-country="BE">
                <span class="ftue--country flag flag-BE"></span
                ><span class="ftue--country text">Belgium</span>
              </li>
              <li data-country="CA">
                <span class="ftue--country flag flag-CA"></span
                ><span class="ftue--country text">Canada</span>
              </li>
              <li data-country="CZ">
                <span class="ftue--country flag flag-CZ"></span
                ><span class="ftue--country text">Czech Republic</span>
              </li>
              <li data-country="DK">
                <span class="ftue--country flag flag-DK"></span
                ><span class="ftue--country text">Denmark</span>
              </li>
              <li data-country="FI">
                <span class="ftue--country flag flag-FI"></span
                ><span class="ftue--country text">Finland</span>
              </li>
              <li data-country="FR">
                <span class="ftue--country flag flag-FR"></span
                ><span class="ftue--country text">France</span>
              </li>
              <li data-country="DE">
                <span class="ftue--country flag flag-DE"></span
                ><span class="ftue--country text">Germany</span>
              </li>
              <li data-country="IE">
                <span class="ftue--country flag flag-IE"></span
                ><span class="ftue--country text">Ireland</span>
              </li>
              <li data-country="IT">
                <span class="ftue--country flag flag-IT"></span
                ><span class="ftue--country text">Italy</span>
              </li>
              <li data-country="NO">
                <span class="ftue--country flag flag-NO"></span
                ><span class="ftue--country text">Norway</span>
              </li>
              <li data-country="PL">
                <span class="ftue--country flag flag-PL"></span
                ><span class="ftue--country text">Poland</span>
              </li>
              <li data-country="ES">
                <span class="ftue--country flag flag-ES"></span
                ><span class="ftue--country text">Spain</span>
              </li>
              <li data-country="SE">
                <span class="ftue--country flag flag-SE"></span
                ><span class="ftue--country text">Sweden</span>
              </li>
              <li data-country="CH">
                <span class="ftue--country flag flag-CH"></span
                ><span class="ftue--country text">Switzerland</span>
              </li>
              <li data-country="NL">
                <span class="ftue--country flag flag-NL"></span
                ><span class="ftue--country text">The Netherlands</span>
              </li>
              <li data-country="GB">
                <span class="ftue--country flag flag-GB"></span
                ><span class="ftue--country text">United Kingdom</span>
              </li>
              <li data-country="US">
                <span class="ftue--country flag flag-US"></span
                ><span class="ftue--country text">United States</span>
              </li>
            </ul>
          </div>
          <div class="ftue--language-selector">
            <p class="ftue--content__info-head">${translations.prefferedLang[language]}</p>
            <ul class="ftue--selector__lang">
              <li data-lang="ZH">
                <span class="ftue--language text">简体中文</span>
              </li>
              <li data-lang="EN">
                <span class="ftue--language text">English</span>
              </li>
              <li data-lang="FR">
                <span class="ftue--language text">Français</span>
              </li>
              <li data-lang="DE">
                <span class="ftue--language text">Deutsch</span>
              </li>
              <li data-lang="JP">
                <span class="ftue--language text">日本語</span>
              </li>
              <li data-lang="NO">
                <span class="ftue--language text">Norsk</span>
              </li>
              <li data-lang="ES">
                <span class="ftue--language text">Español</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="locale-modal--close"
      type="button"
      aria-label="Close (Press escape to close)"
      data-lity-close=""
    >
      ×
    </div>
  </div>
</div>
`;

export default localeSwitcherHtml;
