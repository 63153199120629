function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  const separator = uri.indexOf("?") > -1 ? "&" : "?";

  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }

  // If adding country to url, add it before search
  if (uri.match("search=")) {
    const arr = uri.split("&");
    return `${arr[0] + separator + key}=${value}&${arr[1]}`;
  }

  return `${uri + separator + key}=${value}`;
}

export function updateUrlCountryLanguage({ country, language, isOldPageFormat }) {
  const selectedCountryLowerCase = country.toLowerCase();
  const selectedLanguageLowerCase = language.toLowerCase();

  let redirectUrl = document.location.href;

  if (isOldPageFormat) {
    redirectUrl = updateQueryStringParameter(redirectUrl, "country", selectedCountryLowerCase);
    redirectUrl = updateQueryStringParameter(redirectUrl, "language", selectedLanguageLowerCase);
  } else {
    const shippingRedirectUrl = (selectedCountry, selectedLang) => {
      const urlPath = window.location.pathname.split("/");
      const [_, _country, _lang, ...rest] = urlPath;
      return `${window.location.protocol}//${window.location.hostname}/${selectedCountry}/${selectedLang}/${rest.join(
        "/"
      )}${window.location.search}`;
    };

    redirectUrl = shippingRedirectUrl(selectedCountryLowerCase, selectedLanguageLowerCase);
  }

  return redirectUrl;
}
